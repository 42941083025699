import NewPost from "../../components/News/NewPost";
import Header from "../../components/Util/Header";
import "../../Styles/create.scss";


export default function NewsCreate(props) {

  return (
    <div className="News">
          <NewPost />
    </div>
  );
}
