import { useEffect, useState } from "react";
import CircleLoading from "../Util/CircleLoading";
import Select from "../Util/Select";

export default function MonthWP(props) {
  const jwt = require("jsonwebtoken");
  const { getMonths } = require("../../assets/data/data.js");
  const [data, setData] = useState([]);
  const [year, setYear] = useState("2023");
  const [today, setToday] = useState("");
  const [month, setMonth] = useState("January");
  const [mm, setMM] = useState([]);
  const [dd, setDD] = useState([]);

  useEffect(() => {
    const date = new Date().toISOString().split("T")[0].split("-");
    setYear(date[0]);
    const d = new Date().toISOString().split("T")[0];
    setToday(d);
    const mi = getMonths
      .map((item) => {
        return item.code;
      })
      .indexOf(date[1]);
    if (mi != -1) {
      setMonth(getMonths[mi].month);
    }
  }, []);

  useEffect(() => {
    let d = [];
    getMonths.map((item) => {
      d.push(item["month"]);
    });
    setMM(d);
    const mi = getMonths
      .map((item) => {
        return item.month;
      })
      .indexOf(month);

    if (mi != -1) {
      let x = [];
      for (let index = 1; index < getMonths[mi].days + 1; index++) {
        if (index == 1) {
          const d = new Date(`${year}-${getMonths[mi].code}-01`);

          for (let index = 0; index < d.getDay(); index++) {
            x.push(null);
          }
          const dd = index < 10 ? "0" + index : index.toString();
          const date =
            year.toString() + "-" + getMonths[mi].code.toString() + "-" + dd;
          x.push({
            date: date,
          });
        } else {
          const dd = index < 10 ? "0" + index : index.toString();
          const date =
            year.toString() + "-" + getMonths[mi].code.toString() + "-" + dd;
          x.push({
            date: date,
          });
        }
      }
      if (x.length < 35) {
        const size = 35 - x.length;
        for (let index = 0; index < size; index++) {
          x.push(null);
        }
      }
      console.log(x);
      setDD(x);
    }
  }, [month, year, props.refresh]);

  useEffect(() => {
    if (props.download && data) {
      downloadCSV(data, "monthly work plan summary.csv");
      props.setDownload(false);
    }
  }, [props.download]);

  function downloadCSV(jsonArray, filename) {
    const headerRow = Object.keys(jsonArray[0]).join(",") + "\n";
    const csvContent = jsonArray
      .map((row) => {
        return Object.values(row).join(",");
      })
      .join("\n");
    const fullCSV = headerRow + csvContent;
    const blob = new Blob([fullCSV], { type: "text/csv" });
    const downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = filename;
    downloadLink.click();
  }

  return (
    <div className="monthwp">
      <div className="month">
        <h4>
          {month}, {year}
        </h4>
        <Select
          setChanged={(v) => {
            setMonth(v);
          }}
          data={mm}
          value={month}
        />
        <Select
          setChanged={(v) => {
            setYear(v);
          }}
          value={year}
          data={["2023", "2024", "2025", "2026"]}
        />
      </div>
      <div className="mlist">
        <div className="dow">
          <h4>Sun</h4>
          <h4>Mon</h4>
          <h4>Tue</h4>
          <h4>Wed</h4>
          <h4>Thu</h4>
          <h4>Fri</h4>
          <h4>Sat</h4>
        </div>
        <div className="calendar">
          {dd.map((item, i) => {
            return (
              <Day
                key={i}
                item={item}
                today={today}
                data={data}
                setData={setData}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

const Day = (props) => {
  const [data, setData] = useState(null);
  const [date, setDate] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(props.item?.date);
  }, [props.item]);

  useEffect(() => {
    if (props.item != null && data == null) {
      setLoading(true);
      setData(null);

      fetch(`/api/workplan/searchsingledate/${props.item.date}`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error();
        })
        .then((data) => {
          // console.log(props.item.date);
          setData(data);
          if (data) {
            let x = props.data;
            x.push({
              Date: props.item.date,
              Organizations: data.Orgs,
              Normal: findIndex("Normal", "Reason", data.WPs),
              Reliever: findIndex("Reliever", "Reason", data.WPs),
              "Off Day": findIndex("Off Day", "Reason", data.WPs),
              "Sick Leave": findIndex("Sick Leave", "Reason", data.WPs),
              Leave: findIndex("Leave", "Reason", data.WPs),
              AWOL: findIndex("AWOL", "Reason", data.WPs),
              DayShift: findIndex("Day", "Shift", data.Shift),
              NightShift: findIndex("Night", "Shift", data.Shift),
            });
            props.setData(x);
          }
          // console.log(props.data);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [props.item]);

  function findIndex(value, column, array) {
    const v = array.find((item) => item[column] === value);
    if (v != undefined) return v.total;
    else return 0;
  }

  return (
    <>
      {props.item == null ? (
        <div
          style={{
            backgroundColor: "#60606010",
          }}
          className="day mob"
        ></div>
      ) : (
        <div
          onClick={() => {
            if (data) {
              window.location.href = "/wp/single/" + props.item.date;
            }
          }}
          className="day"
        >
          <h6>{props.item.date.split("-")[2]}</h6>

          {data && data?.WPs?.length > 0 && (
            <>
              <h4>Work plans: {data ? data?.WPs?.length : 0}</h4>
              <h4>Organizations: {data ? data?.Orgs : 0}</h4>
              <br />
              <h3>Assignment</h3>
              <hr />
            </>
          )}
          {data &&
            data?.WPs?.length > 0 &&
            data?.WPs?.map((item, i) => {
              return <Row key={i} item={item} />;
            })}
          {data && data?.WPs?.length > 0 && (
            <>
              <br />
              <h3>Shifts</h3>
              <hr />
            </>
          )}
          {data &&
            data?.WPs?.length > 0 &&
            data?.Shift?.map((item, i) => {
              return <Shift key={i} item={item} />;
            })}
          {loading && <CircleLoading />}
        </div>
      )}
    </>
  );
};

const Row = (props) => {
  const colors = ["#FFF1E8", "#D7F3FF", "#FFF1F1", "#B5CB99", "#F1F4FF"];
  const [color, setColor] = useState("#FFF8C9");

  useEffect(() => {
    switch (props.item.Reason) {
      case "Normal":
        setColor("#FFF8C9");
        break;
      case "Reliever":
        setColor(colors[0]);
        break;
      case "Off Day":
        setColor(colors[1]);
        break;
      case "Sick Leave":
        setColor(colors[2]);
      case "Leave":
        setColor(colors[3]);
        break;
      case "AWOL":
        setColor(colors[4]);
        break;
      default:
        break;
    }
  }, [props.item]);
  return (
    <div style={{ backgroundColor: color }} className="row">
      <p>
        {props.item.Reason}: {props.item.total}
      </p>
    </div>
  );
};

const Shift = (props) => {
  const colors = ["#FFF1E8", "#D7F3FF", "#FFF1F1", "#D7F3FF", "#F1F4FF"];
  const [color, setColor] = useState("#e5e5e5");

  useEffect(() => {
    switch (props.item.Shift) {
      case "Day":
        setColor("orange");
        break;
      case "Night":
        setColor("brown");
        break;

      default:
        break;
    }
  }, [props.item]);
  return (
    <div style={{ backgroundColor: color }} className="row">
      <p>
        {props.item.Shift}: {props.item.total}
      </p>
    </div>
  );
};
