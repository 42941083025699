import { useEffect, useRef, useState } from "react";
import placeholder from "../../assets/imgs/placeholder.png";
import WaveLoading from "../Util/WaveLoading";
import Select from "../Util/Select";
import Pagination from "../Util/Pagination";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";

export default function DailyWP(props) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  var dayNames = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
  const { getMonths } = require("../../assets/data/data.js");
  const yy = ["2023", "2024", "2025"];
  const [year, setYear] = useState("2023");
  const [offset, setOffset] = useState(0);
  const [today, setToday] = useState(null);
  const [month, setMonth] = useState(null);
  const [mm, setMM] = useState([]);
  const [dd, setDD] = useState([]);

  useEffect(() => {
    const date = new Date().toISOString().split("T")[0].split("-");
    setYear(date[0]);
    const d = new Date().toISOString().split("T")[0];
    setToday(d);
    let x = [];
    const mi = getMonths
      .map((item) => {
        x.push(item.month);
        return item.code;
      })
      .indexOf(date[1]);

    getMonths.map((item) => {
      x.push(item.month);
    });
    setMM(x);
    if (mi != -1) {
      setMonth(getMonths[mi].month);
    }
  }, []);

  useEffect(() => {
    let d = [];
    getMonths.map((item) => {
      d.push(item["month"]);
    });
    setMM(d);
    const mi = getMonths
      .map((item) => {
        return item.month;
      })
      .indexOf(month);

    if (mi != -1) {
      let x = [];
      const dtoday = parseInt(today.split("-")[2]);

      const size =
        getMonths[mi].days - dtoday < 12 ? getMonths[mi].days - dtoday : 12;
      for (
        let index = dtoday == 1 ? dtoday : dtoday - 1;
        index < size + dtoday + 1;
        index++
      ) {
        const day = `${year}-${mi + 1}-${index < 10 ? "0" + index : index}`;
        var date = new Date(day);
        var dayOfWeek = date.getDay();
        x.push({ day: dayNames[dayOfWeek], date: index, today: day });
      }
      if (x.length < 14) {
        const sz = 14 - x.length;
        for (let index = 0; index < sz; index++) {
          x.push({ day: "", date: "", today: "day" });
        }
      }
      setDD(x);
    }
  }, [month, year, today]);

  useEffect(() => {
    if (today != null) {
      setData(null);
      setLoading(true);
      fetch(`/api/workplan/searchdate/${today}/${offset}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setLoading(false);
          setData(data);
          console.log(data);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [today, offset, props.refresh]);

  useEffect(() => {
    if (props.download && data && data?.data?.length > 0) {
      downloadCSV(data.data, "daily workplans.csv");
      props.setDownload(false);
    }
  }, [props.download]);

  function downloadCSV(jsonArray, filename) {
    const headerRow = Object.keys(jsonArray[0]).join(",") + "\n";
    const csvContent = jsonArray
      .map((row) => {
        return Object.values(row).join(",");
      })
      .join("\n");
    const fullCSV = headerRow + csvContent;
    const blob = new Blob([fullCSV], { type: "text/csv" });
    const downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = filename;
    downloadLink.click();
  }

  function addDaysToDate(date, daysToAdd) {
    const result = new Date(date);
    result.setDate(result.getDate() + daysToAdd);
    return result.toISOString().split("T")[0];
  }

  function minusDaysToDate(date, daysToAdd) {
    const result = new Date(date);
    result.setDate(result.getDate() - daysToAdd);
    return result.toISOString().split("T")[0];
  }

  return (
    <div className="dailywp">
      <div className="month">
        <h4>
          {month}, {year}
        </h4>
        <Select
          setChanged={(v) => {
            setMonth(v);
          }}
          data={mm}
          value={month}
        />
        <Select
          setChanged={(v) => {
            setYear(v);
          }}
          value={year}
          data={["2023", "2024", "2025", "2026"]}
        />
      </div>

      <div className="dates">
        <i
          onClick={() => {
            let d = minusDaysToDate(today, 1);
            setToday(d);
            console.log(d);
          }}
          className="fa fa-angle-left"
        ></i>
        {dd.length > 0 &&
          dd.map((item, i) => {
            return (
              <DateItem key={i} item={item} setToday={setToday} today={today} />
            );
          })}
        <i
          onClick={() => {
            let d = addDaysToDate(today, 1);
            setToday(d);
            console.log(d);
          }}
          className="fa fa-angle-right"
        ></i>
      </div>

      <div className="dlist">
        {data &&
          data?.data?.length > 0 &&
          data?.data?.map((item, i) => {
            return (
              <SOItem
                key={i}
                item={item}
                setRefresh={props.setRefresh}
                setLoading={setLoading}
                refresh={props.refresh}
              />
            );
          })}
      </div>
      <Pagination
        totalItems={data?.total}
        currentPage={offset}
        onPageChange={setOffset}
      />
      {loading && <WaveLoading />}
    </div>
  );
}

const DateItem = (props) => {
  return (
    <div
      onClick={() => {
        props.setToday(props.item.today);
      }}
      className={props.item.today === props.today ? "active" : "dateitem"}
    >
      <p>{props.item.date}</p>
      <h4>{props.item.day}</h4>
    </div>
  );
};

const SOItem = (props) => {
  const colors = ["#FFF1E8", "#D7F3FF", "#FFF1F1", "#B5CB99", "#F1F4FF"];
  const [color, setColor] = useState("#FFF8C9");
  const [showing, setShowing] = useState(false);

  useEffect(() => {
    switch (props.item.Reason) {
      case "Normal":
        setColor("#FFF8C9");
        break;
      case "Reliever":
        setColor(colors[0]);
        break;
      case "Off Day":
        setColor(colors[1]);
        break;
      case "Sick Leave":
        setColor(colors[2]);
      case "Leave":
        setColor(colors[3]);
        break;
      case "AWOL":
        setColor(colors[4]);
        break;
      default:
        break;
    }
  }, [props.item]);

  function deleteWP() {
    props.setLoading(true);
    fetch(`/api/workplan/${props.item.ID}`, {
      method: "DELETE",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        props.setLoading(true);
        props.setRefresh(!props.refresh);
      })
      .catch((e) => {
        props.setLoading(true);
      });
  }

  return (
    <>
      <div style={{ backgroundColor: color }} className="soitem">
        <div className="update">
          <AiOutlineDelete
            onClick={() => {
              deleteWP();
            }}
            color="white"
            size={24}
            className="pencil"
          />
          <FiEdit
            onClick={() => {
              setShowing(true);
            }}
            color="white"
            size={24}
            className="pencil"
          />
        </div>

        <div className="title">
          <img src={props.item.Photo ? props.item.Photo : placeholder} alt="" />
          <div>
            <h4>{props.item.Name}</h4>
            <p>
              <b>Phone: </b> {props.item.Phone}
            </p>
            <p>
              <b>Employee No: </b> {props.item.EmployeeNo}
            </p>
          </div>
        </div>

        <h4>Shift Details</h4>
        <hr />
        <div className="div3equal">
          <p
            style={{
              backgroundColor: props.item.Shift == "Day" ? "orange" : "brown",
              padding: "2px",
              color: "white",
            }}
          >
            <b>Shift: </b> {props.item.Shift}
          </p>
          <p>
            <b>Date: </b> {props.item.Date}
          </p>
          <p>
            <b>Type: </b> {props.item.Reason}
          </p>
        </div>

        <h4>Organization Details</h4>
        <hr />
        <p>
          <b>Organization: </b>
          {props.item.Organization}
        </p>
        <p>
          <b>Address: </b> {props.item.City}, {props.item.Address}
        </p>
      </div>
      {showing && (
        <EditPopup
          setShowing={setShowing}
          ID={props.item.ID}
          Reason={props.item.Reason}
          setRefresh={props.setRefresh}
          setLoading={props.setLoading}
          refresh={props.refresh}
        />
      )}
    </>
  );
};

const EditPopup = (props) => {
  const rs = useRef();

  function updateWP() {
    props.setLoading(true);
    fetch(`/api/workplan/${props.ID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ Reason: rs.current.getValue() }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        props.setLoading(true);
        props.setRefresh(!props.refresh);
      })
      .catch((e) => {
        props.setLoading(true);
      });
  }
  return (
    <div className="editpopup">
      <div className="card">
        <i
          onClick={() => {
            props.setShowing(false);
          }}
          className="fa fa-times"
        ></i>
        <h3>Update Work Plan</h3>
        <hr />
        <Select
          ref={rs}
          value={props.Reason}
          label="Select Work plan type"
          data={[
            "Normal",
            "Reliever",
            "Temporary Work Orders",
            "Off Day",
            "Sick Leave",
            "Leave",
            "AWOL",
          ]}
        />
        <button
          onClick={() => {
            updateWP();
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );
};
