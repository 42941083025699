import { useState } from "react";
import logo from "../../assets/imgs/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faBuildingLock,
  faBuildingNgo,
  faFileAlt,
  faFolder,
  faHomeUser,
  faLocationArrow,
  faMapLocation,
  faMessage,
  faSignOut,
  faTools,
  faUser,
  faUserNinja,
} from "@fortawesome/free-solid-svg-icons";

export default function Navigation(props) {
  const pathname = window.location.pathname.split("/");

  const Item = (params) => {
    const [showing, setShowing] = useState(false);

    return (
      <div
        style={{
          backgroundColor: showing ? "#60606010" : "transparent",
        }}
      >
        <div
          onClick={() => {
            if (params.options?.length === 0) {
              window.location.href = params.url;
            }
            if (params.url == "/logout") {
              localStorage.removeItem("fmgdfhgfhtknfmgdfhgfhtkn");
              window.location.href = "/login";
            }
          }}
          onMouseEnter={() => {
            // setShowing(true);
          }}
          className={params.link == params.active ? "active" : "item"}
          style={{
            padding: params.showing ? "1em" : "5x 0 5px 0",
            gridTemplateColumns: params.showing ? "20px auto" : "auto",
          }}
        >
          <FontAwesomeIcon className="fa" icon={params.icon} />
          {params.showing && <p>{params.txt}</p>}
        </div>
        {showing &&
          params.options.length > 0 &&
          params.options.map((item, i) => {
            return (
              <a key={i} href={params.url + "/" + item}>
                {item}
              </a>
            );
          })}
      </div>
    );
  };

  return (
    <div
      style={{ width: props.showing ? "250px" : "fit-content" }}
      className="navigation"
    >
      <div className="logo">
        <img
          style={{ maxWidth: props.showing ? "70px" : "30px" }}
          src={logo}
          alt=""
        />
       <br />
      </div>
      <Item
        url="/"
        link=""
        txt="Home"
        active={pathname[1]}
        icon={faHomeUser}
        options={[]}
        showing={props.showing}
      />
      <Item
        url="/reports"
        link="reports"
        txt="Reports"
        active={pathname[1]}
        icon={faFileAlt}
        options={[]}
        showing={props.showing}
      />
      <Item
        txt="Tracking"
        url="/tracking"
        link="tracking"
        active={pathname[1]}
        icon={faLocationArrow}
        options={[]}
        showing={props.showing}
      />
      <Item
        txt="Work Plan"
        url="/wp"
        link="wp"
        active={pathname[1]}
        icon={faTools}
        options={[]}
        showing={props.showing}
      />
      <Item
        url="/gis"
        link="gis"
        txt="GIS"
        active={pathname[1]}
        icon={faMapLocation}
        options={[]}
        showing={props.showing}
      />
      <Item
        url="/messages"
        link="messages"
        txt="Messages"
        active={pathname[1]}
        icon={faMessage}
        options={[]}
        showing={props.showing}
      />
      <Item
        url="/organizations"
        link="organizations"
        txt="Organizations"
        active={pathname[1]}
        icon={faBuildingLock}
        options={[]}
        showing={props.showing}
      />
      <Item
        txt="Security Officers"
        url="/so"
        link="so"
        icon={faUserNinja}
        active={pathname[1]}
        options={[]}
        showing={props.showing}
      />
      <Item
        url="/documents"
        link="documents"
        txt="Documents"
        icon={faFolder}
        active={pathname[1]}
        options={[]}
        showing={props.showing}
      />
      <Item
        txt="Portal Users"
        url="/users"
        link="users"
        icon={faUser}
        active={pathname[1]}
        options={[]}
        showing={props.showing}
      />

      <Item
        txt="Settings"
        url="/settings"
        link="settings"
        icon={faTools}
        active={pathname[1]}
        code="&#xf2c0;"
        options={[]}
        showing={props.showing}
      />
      <Item
        txt="Logout"
        icon={faSignOut}
        active={pathname[1]}
        url="/logout"
        options={[]}
        showing={props.showing}
      />
    </div>
  );
}
