import { useRef, useState, useEffect } from "react";
import Input from "../Util/Input";
import Select from "../Util/Select";
import "../../Styles/workplan.scss";
import WaveLoading from "../Util/WaveLoading";

export default function NewMessage(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [fo, setFO] = useState(null);
  const [fodata, setFOData] = useState(null);
  const [su, setSU] = useState(null);
  const [rtype, setRType] = useState(null);
  const to = useRef();
  const title = useRef();
  const message = useRef();
  const type = useRef();

  const [body, setBody] = useState({
    To: "",
    Title: null,
    Message: null,
    Type: "Outgoing",
    From: "Admin",
    Type: null,
  });
  const [showing, setShowing] = useState(true);
  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  function searchByPhone(role, q) {
    if (role === "Organization") {
      fetch(`/api/organization/search/${q}`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error();
        })
        .then((data) => {
          setSU(data);
        })
        .catch((e) => {});
    } else {
      fetch(`/api/mobile/seachbyphone/${q}`)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error();
        })
        .then((data) => {
          setFO(data);
        })
        .catch((e) => {});
    }
  }

  const createDocument = (e) => {
    setError("");
    let d = body;
    d.Title = title.current.getValue();
    d.Message = message.current.getValue();
    d.Type = type.current.getValue();
    setBody(d);

    const chck = Object.values(d);
    let valid = true;
    chck.map((item) => {
      if (item === null) {
        valid = false;
      }
    });
    if (!valid) return setError("All fields are required!");

    setLoading(true);

    fetch("/api/messages/create", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(d),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            window.location.href = "/messages";
          }, 1000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  const SOList = (params) => {
    return (
      <div
        onClick={() => {
          let d = body;
          d.UserID = params.item.UserID;
          setBody(d);
          setFOData(params.item);
          setFO(null);
        }}
        className="so_list"
      >
        <h2>{params.item.Name}</h2>
        <h5>
          <b>Employee No: {params.item.EmployeeNo}</b>
        </h5>
        <h5>
          <b>Category: {params.item.Category}</b>
        </h5>
      </div>
    );
  };

  return (
    <div className="messages">
      <div className="container">
        <div className="new">
          <div className="cpcontainer">
            <div className="tp">
              <h3>New Message</h3>
              <h4
                onClick={() => {
                  props.setCreating(false);
                }}
              >
                {" "}
                <i className="fa fa-arrow-right"></i> Messages
              </h4>
            </div>
            <div className="bdy">
              <div className="section">
                <h4>Message/Notification Details</h4>
                <hr />
                <Input ref={title} label="Title *" />
                <Input ref={message} label="Message *" type="textarea" />
              </div>

              <div className="section">
                <h4>Recipient Details</h4>
                <hr />
                <Select
                  ref={type}
                  setChanged={(e) => {}}
                  label="Select Recipients"
                  data={["All Officers", "Select Officers"]}
                />
                <div className="search">
                  <Input
                    type="number"
                    handleChange={(e) => {
                      searchByPhone("Field Officer", e);
                    }}
                    label="Employee Code"
                    hint="Type to search"
                  />
                  {fo && (
                    <div className="search_list">
                      {fo.map((item, i) => {
                        return <SOList key={i} item={item} />;
                      })}
                    </div>
                  )}
                </div>
              </div>

              <h6>{error}</h6>

              <button
                onClick={() => {
                  createDocument();
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        {loading && <WaveLoading />}
      </div>
    </div>
  );
}

const Item = (props) => {
  return (
    <>
      {props.data && (
        <div className="item">
          <div>
            <h4>{props.title}</h4>
            <i
              onClick={() => {
                props.clearEvent();
              }}
              className="fa fa-times"
            ></i>
          </div>

          <hr />
          <p>{props.data.Organization}</p>
          <p>
            {props.data.City}, {props.data.Address}
          </p>
        </div>
      )}
    </>
  );
};

const SOItem = (props) => {
  return (
    <>
      {props.data && (
        <div className="item">
          <div>
            <h4>{props.title}</h4>
            <i
              onClick={() => {
                props.clearEvent();
              }}
              className="fa fa-times"
            ></i>
          </div>

          <hr />
          <p>Name: {props.data.Name}</p>
          <p>Employee No:{props.data.EmployeeNo}</p>
          <p>Category: {props.data.Category}</p>
        </div>
      )}
    </>
  );
};
