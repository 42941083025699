import "../Styles/home.scss";

import GIS from "../components/GIS/GIS";
import Header from "../components/Util/Header";
import Navigation from "../components/Util/Navigation";
import { useState } from "react";
import { useEffect } from "react";
import PortalUsers from "../components/Users/PortalUsers";
import Settings from "../components/Settings/Settings";
import CreateWorkPlan from "../components/WorkPlan/CreateWorkPlan";
import WPStats from "../components/Stats/WPStats";
import NewsCreate from "../components/News/NewsCreate";
import CreateProject from "../components/Projects/CreateProject";
import NewOrg from "../components/Organizations/NewOrg";
import Organizations from "../components/Organizations/Organizations";
import Officers from "../components/Officers/Officers";
import SingleOfficer from "../components/Officers/SingleOfficer";
import WorkPlan from "../components/WorkPlan/WorkPlan";
import SO from "../components/SO/SO";
import NewSO from "../components/SO/NewSO";
import SingleWP from "../components/WorkPlan/SingleWP";
import UpdateOrg from "../components/Organizations/UpdateOrg";
import UpdateSO from "../components/SO/UpdateSO";
import NewHR from "../components/HR/NewHR";
import HR from "../components/HR/HR";
import Messages from "../components/Messages/Messages";
import NewMessage from "../components/Messages/NewMessage";
import Reports from "../components/Reports/Reports";
import Main from "../components/Folders/Main";

export default function Home(props) {
  const [showing, setShowing] = useState(true);
  const pathname = window.location.pathname.split("/");
  const [path, setPath] = useState(null);

  const handleResize = () => {
    if (window.innerWidth < 1024) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
    const p = localStorage.getItem("path");

    if (p != null) setPath(p);
    else setPath("Root");
  }, []);

  return (
    <div className="home">
      <div
        style={{ gridTemplateColumns: !showing ? "auto 1fr" : "250px 1fr" }}
        className="main"
      >
        <div className="left_panel">
          <Navigation showing={showing} setShowing={setShowing} />
        </div>
        <div className="right_panel">
          <Header showing={showing} setShowing={setShowing} />
          <div className="full">
            {(pathname[1] === "Stats" || pathname[1] === "") && (
              <WPStats showing={showing} />
            )}
            {pathname[1] === "gis" && <GIS showing={showing} />}
            {pathname[1] === "reports" && <Reports showing={showing} />}
            {pathname[1] === "messages" && <Messages showing={showing} />}
            {pathname[1] === "messages-new" && <NewMessage showing={showing} />}
            {pathname[1] === "tracking" && pathname.length > 2 && (
              <SingleOfficer showing={showing} path={pathname[2]} />
            )}

            {pathname[1] === "tracking" && pathname.length === 2 && (
              <Officers showing={showing} path={pathname[2]} />
            )}

            {(pathname[1] === "news" || pathname[2] === "Create") && (
              <NewsCreate showing={showing} />
            )}
            {pathname[1] === "organizations" && pathname.length == 2 && (
              <Organizations showing={showing} />
            )}
            {pathname[1] === "organizations" && pathname[2] === "new" && (
              <NewOrg showing={showing} />
            )}
            {pathname[1] === "organizations" && pathname[2] === "update" && (
              <UpdateOrg showing={showing} />
            )}
            {pathname[1] === "documents" && (
              <Main showing={showing} path={path} setPath={setPath} />
            )}

            {pathname[1] === "projects" && pathname[2] === "Add" && (
              <CreateProject showing={showing} />
            )}
            {pathname[1] === "so" && pathname[2] === "update" && <UpdateSO />}

            {pathname[1] === "so" && pathname[2] === "new" && <NewSO />}
            {pathname[1] === "so" && pathname.length == 2 && <SO />}

            {pathname[1] === "so" &&
              pathname[2] === "hr" &&
              pathname[3] === "new" && <NewHR />}
            {pathname[1] === "so" &&
              pathname[2] === "hr" &&
              pathname[3] == "so" && <HR />}

            {pathname[1] === "portal" && pathname[2] === "Users" && (
              <PortalUsers />
            )}

            {pathname[1] === "users" && <PortalUsers />}
            {pathname[1] === "wp" && pathname.length == 2 && <WorkPlan />}
            {pathname[1] === "wp" && pathname[2] === "new" && (
              <CreateWorkPlan />
            )}
            {pathname[1] === "wp" && pathname[2] === "single" && <SingleWP />}
            {pathname[1] === "settings" && <Settings />}
          </div>
        </div>
      </div>
    </div>
  );
}
