import NewProject from "./NewProject";
import Header from "../Util/Header";
import "../../Styles/create.scss";


export default function CreateNew(props) {

  return (
    <div className="Projects">
          <NewProject />
    </div>
  );
}
