import React from "react";
import logo from "../assets/imgs/logo.png";
import "../Styles/terms.scss";

class PrivacyPolicyPage extends React.Component {
  render() {
    return (
      <div className="terms">
        <div className="top">
          <div className="logo">
            <img src={logo} alt="" />
            <div>
              <h3>FieldMasta</h3>
              <p>Your ERP of choice</p>
            </div>
          </div>
          <div className="nav">
            <h4
              onClick={() => {
                window.open("/terms", "_blank");
              }}
            >
              Terms & Conditions
            </h4>
            <h4
              onClick={() => {
                window.open("/privacy", "_blank");
              }}
            >
              Privacy Policy
            </h4>
          </div>
        </div>
        <div className="container">
          <h1>Privacy Policy</h1>
          <p>Last Updated: 27/9/2023</p>
          <p>
            Welcome to Secu Watch's Privacy Policy. This Privacy Policy explains
            how we collect, use, disclose, and protect your personal information
            when you use our Security Officers Management System and Mobile App
            (the "Service"). By using the Service, you agree to the terms of
            this Privacy Policy.
          </p>
          <h2>1. Information We Collect</h2>
          <p>
            1.1. Personal Information: We may collect personal information,
            including but not limited to your name, contact information, and
            login credentials, when you register for an account or use our
            Service.
          </p>
          <p>
            1.2. Location Information: Our Mobile App tracks the real-time
            location of security officers. Location data may be collected when
            the app is in use, even when it is running in the background.
          </p>
          <h2>2. How We Use Your Information</h2>
          <p>2.1. We may use your personal information to:</p>
          <ul>
            <li>Provide and maintain the Service.</li>
            <li>Track the real-time location of security officers.</li>
            <li>Communicate with you regarding the Service.</li>
          </ul>
          <p>
            2.2. Location data is used for the purpose of security management
            and efficiency, including officer tracking and response management.
          </p>
          <h2>3. How We Use Your Information</h2>
          <h3>3.1. Providing Services:</h3>{" "}
          <p>
            {" "}
            We use your personal information to provide and maintain our
            services, including tracking the location of security officers for
            security management and efficiency.
          </p>
          <h3>3.2. Communication:</h3>{" "}
          <p>
            {" "}
            We may use your contact information to communicate with you about
            the service, updates, and important notices.
          </p>
          <h2>4. Data Sharing</h2>
          <p>
            We do not sell, rent, or trade your personal information to third
            parties. However, we may share your information in the following
            circumstances:
          </p>
          <ul>
            <li>
              With authorized personnel and security administrators for security
              management purposes.
            </li>
            <li>
              When required by law or to protect our rights, privacy, safety, or
              property.
            </li>
          </ul>
          <h2>5. Data Security</h2>
          <p>
            We implement security measures to protect your data, including
            encryption and access controls. However, no method of data
            transmission or storage is entirely secure, and we cannot guarantee
            absolute security.
          </p>
          <h2>6. User Choices</h2>
          <p>
            You have the following rights regarding your personal information:
          </p>
          <ul>
            <li>
              Access, update, or delete your personal information by contacting
              us.
            </li>
            <li>Opt-out of non-essential communications.</li>
          </ul>
          <h2>7. Changes to Privacy Policy</h2>
          <p>
            We may update this Privacy Policy to reflect changes in our
            practices or legal requirements. We will notify you of any
            significant changes via email or through the app. Please review the
            updated policy carefully.
          </p>
          <h2>8. Children's Privacy</h2>
          <p>
            Our services are not intended for individuals under the age of 18.
            We do not knowingly collect or maintain personal information from
            children under 18.
          </p>
          <h2>9. Contact Information</h2>
          <p>
            If you have questions, concerns, or requests regarding your privacy,
            please contact us at: info@secuwatch.dat.co.ke
          </p>
          <h2>10. Compliance with Laws and Regulations</h2>
          <p>
            We operate in accordance with applicable data protection laws and
            regulations in Kenya.
          </p>
          <p>
            By using Secu Watch, you acknowledge that you have read, understood,
            and agree to the terms of this Privacy Policy.
          </p>
        </div>
      </div>
    );
  }
}

export default PrivacyPolicyPage;
