import React, { PureComponent } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";



export default class StackedAreaChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      aspect: 3,
    };
  }

  handleResize = () => {
    if (window.innerWidth < 600) {
      this.setState({ aspect: 1.5 });
    } else {
      this.setState({ aspect: 3 });
    }
  };

  componentDidMount() {
    this.handleResize();
  }

  render() {
    return (
      <ResponsiveContainer width={"95%"} aspect={this.state.aspect}>
        <AreaChart
          width={"100%"}
          height={"100%"}
          data={this.props.data}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="10 10" />
          <XAxis dataKey="name" />
          <YAxis domain={[0, 4.43]} />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="value"
            stackId="1"
            stroke="#8884d8"
            fill="#8884d8"
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  }
}
