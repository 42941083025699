import { useRef, useState, useEffect } from "react";
import Loading from "../Util/Loading";
import "../../Styles/so.scss";
import Input from "../Util/Input";
import placeholder from "../../assets/imgs/placeholder.png";
import Select from "../Util/Select";
import WaveLoading from "../Util/WaveLoading";

export default function UpdateSO(props) {
  const [loading, setLoading] = useState(false);
  const [body, setBody] = useState({
    Name: null,
    Phone: null,
    Email: null,
    Gender: null,
    EmployeeNo: null,
    Category: null,
    Photo: null,
  });
  const [imageSrc, setImageSrc] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState("");
  const fileInputRef = useRef(null);
  const nm = useRef();
  const phn = useRef();
  const eml = useRef();
  const gdr = useRef();
  const empno = useRef();
  const cat = useRef();
  const pathname = window.location.pathname.split("/");

  useEffect(() => {
    fetch(`/api/mobile/${pathname[3]}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        let d = { ...body };
        d.Name = data?.Name;
        d.Phone = data?.Phone;
        d.Email = data?.Email;
        d.Gender = data?.Gender;
        d.EmployeeNo = data?.EmployeeNo;
        d.Category = data?.Category;
        d.Photo = data?.Photo;
        setBody(d);
        console.log(d);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    if (body.Photo != null) {
      fetch(`/api/uploads/${body.Photo}`)
        .then((res) => {
          if (res.ok) return res.blob();
          else throw Error("");
        })
        .then((blob) => {
          const reader = new FileReader();
          reader.onload = () => {
            setImageSrc(reader.result);
          };
          reader.readAsDataURL(blob);
        })
        .catch((e) => {});
    }
  }, [body.Photo]);

  function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }

  const createUser = () => {
    let d = { ...body };
    d.Name = titleCase(nm.current.getValue());
    d.Phone = phn.current.getValue();
    d.Email = eml.current.getValue();
    d.Gender = gdr.current.getValue();
    d.EmployeeNo = empno.current.getValue();
    d.Category = cat.current.getValue();
    if (selectedFile != null) d.Photo = selectedFile;
    setBody(d);

    setLoading(true);
    setError("");

    const validateForm = () => {
      let result = true;
      if (!validateEmail(d.Email)) {
        result = false;
        setError("Please Enter a valid email address!");
        setLoading(false);
        return result;
      }
      const chck = Object.values(d);
      let valid = true;
      chck.map((item) => {
        if (item == "") {
          valid = false;
        }
      });

      if (!valid) {
        setError("All fields are required!");
        return false;
      }
      if (d.Phone.length != 10) {
        setError("Invalid phone number!");
        return false;
      }
      if (d.Name.split(" ").length < 2) {
        setError("Provide at least 2 names!");
        return false;
      }

      return result;
    };

    if (validateForm()) {
      const formData = new FormData();

      for (const i in d) {
        formData.append(i, d[i]);
      }
      setLoading(true);
      fetch(`/api/mobile/${pathname[3]}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          Accept: "application/json",
        },
        body: formData,
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else throw Error("Creation Failed!!!");
        })
        .then((data) => {
          setLoading(false);
          if (data.success) {
            setError(data.success);
            setTimeout(() => {
              window.location.href = "/so";
            }, 1000);
          } else {
            setError(data.error);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (selectedFile !== null) {
      const reader = new FileReader();
      reader.onload = () => {
        let d = body;
        d.Photo = selectedFile;
        setBody(d);
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  }, [selectedFile]);

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    const selectedFile = e.target.files[0];
    console.log("Selected file:", selectedFile);
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <div className="so">
      <div className="container">
        <div className="createorg">
          <div className="cpcontainer">
            <div className="tp">
              <h3>New Security Officer</h3>
              <h4
                onClick={() => {
                  window.location.href = "/so";
                }}
              >
                <i className="fa fa-arrow-right"></i> List
              </h4>
            </div>
            <form
              className="bdy"
              onSubmit={(e) => {
                e.preventDefault();
              }}
              autoComplete="off"
            >
              <div className="div2equal">
                <div>
                  <div className="section">
                    <h4>Officer Details</h4>
                    <hr />
                    <Input
                      ref={nm}
                      type="text"
                      label="Name"
                      value={body?.Name}
                    />
                    <Input
                      ref={phn}
                      type="text"
                      label="Phone *"
                      value={body?.Phone}
                    />
                    <Select
                      ref={gdr}
                      data={["Male", "Female"]}
                      label="Gender *"
                      value={body?.Gender}
                    />
                  </div>
                </div>

                <div className="section">
                  <h4>Profile Photo (optional)</h4>
                  <hr />
                  <div className="preview">
                    <img src={imageSrc ? imageSrc : placeholder} alt="" />
                    <i onClick={handleIconClick} className="fa fa-camera"></i>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      ref={fileInputRef}
                    />
                  </div>
                </div>
              </div>
              <div className="section">
                <h4>Epmloyment Details</h4>
                <hr />
                <div className="div2equal">
                  <Input
                    ref={empno}
                    type="number"
                    label="Employee Number *"
                    value={body?.EmployeeNo}
                  />
                  <Select
                    ref={cat}
                    data={[
                      "Security Officer",
                      "Dog Handler",
                      "Supervisor",
                      "Motorized Supervisor",
                    ]}
                    label="Category *"
                    value={body?.Category}
                  />
                </div>
              </div>

              <div className="section">
                <h4>Login Details</h4>
                <hr />
                <Input
                  ref={eml}
                  type="email"
                  label="Email *"
                  value={body?.Email}
                />
              </div>
              <h6>{error}</h6>
              <button
                onClick={() => {
                  createUser();
                }}
              >
                Submit
              </button>
            </form>
            {loading && <WaveLoading />}
          </div>
        </div>
      </div>
    </div>
  );
}
