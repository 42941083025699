import { useEffect, useState } from "react";
import $ from "jquery";
import GeoJSON from "ol/format/GeoJSON";
import { Icon } from "ol/style.js";
import VectorSource from "ol/source/Vector";
import Circle from "ol/style/Circle";
import Style from "ol/style/Style";
import Fill from "ol/style/Fill";
import Text from "ol/style/Text";
import Stroke from "ol/style/Stroke";
import Feature from "ol/Feature";
import { Point } from "ol/geom";
import WebGLPointsLayer from "ol/layer/WebGLPoints";

export default function Query(props) {
  const operators = ["=", ">", "<", ">=", "<=", "<>", "ILIKE"];
  const [layrs, setLayrs] = useState([]);
  const [title, setTitle] = useState("");
  const [layerIndex, setlayerIndex] = useState(-1);
  const [columns, setColumns] = useState([]);
  const [lcolumn, setLColumn] = useState("");
  const [operator, setOperator] = useState("");
  const [value, setValue] = useState("");
  const [error, setError] = useState("");

  const dt = {
    FarmerDetails: [
      "NationalID",
      "Name",
      "Phone",
      "Gender",
      "AgeGroup",
      "User",
      "FarmingType",
    ],
    FarmerAddresses: ["SubCounty", "Ward", "Village"],
    FarmerResources: [
      "TotalAcreage",
      "CropAcreage",
      "LivestockAcreage",
      "FarmOwnership",
      "IrrigationType",
    ],
    FarmerGroups: ["Name", "Type"],
    FarmerValueChains: [
      "Name",
      "Variety",
      "Unit",
      "ApproxAcreage",
      "AvgYearlyProduction",
      "AvgYHarvestProduction",
    ],
  };

  useEffect(() => {
    const d = Object.keys(dt);
    setLayrs(d);
    populateColumns(d[0]);
    setTitle(d[0]);
  }, []);

  function populateColumns(t) {
    const d = dt[t];
    setColumns(d);
    setLColumn(d[0]);
  }

  function addToMap() {
    props.setIsLoading(true);
    props.removeByTitle("farmers");

    fetch(
      `/api/farmeraddress/filtermapdata/${title}/${lcolumn}/${operator}/${value}`
    )
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        props.setIsLoading(false);
      
        if (data?.length > 0) {
          props.setData(data)
          let features = new Array(data.length);
          data.map((item, i) => {
            features[i] = new Feature({
              geometry: new Point([
                parseFloat(item.Longitude),
                parseFloat(item.Latitude),
              ]),
            });
            const obj = Object.entries(item);
            obj.map((dt) => {
              features[i].values_[dt[0]] = dt[1];
            });
          });

          const vs = new VectorSource({
            features: features,
            format: new GeoJSON(),
          });
          const f = new WebGLPointsLayer({
            title: "farmers",
            style: farmerStyle,
            disableHitDetection: false,
            source: vs,
          });
          props.map.addLayer(f);
          props.map.getView().fit(vs.getExtent(), {
            padding: [100, 100, 100, 100],
          });
        }
      })
      .catch((e) => {
        props.setIsLoading(false);
      });
  }

  let farmerStyle = {
    symbol: {
      symbolType: "circle",
      size: 10,
      color: "green",
      rotateWithView: false,
    },
  };

  return (
    <div className="query">
      <div className="basic_styler">
        <h3>Query data</h3>
        <hr />
        <p>Select Table</p>
        <div className="row">
          <i className="fa fa-map"></i>
          <select
            onChange={(e) => {
              setError("");
              populateColumns(e.target.value);
              setTitle(e.target.value);
            }}
            name=""
            id=""
          >
            <option value="Select Layer">Select Layer</option>
            {layrs.length > 0 &&
              layrs.map((item, i) => {
                return (
                  <option key={i} value={item}>
                    {item}
                  </option>
                );
              })}
          </select>
        </div>

        <p>Select Column</p>
        <div className="row">
          <i className="fa fa-bars"></i>
          <select
            value={lcolumn}
            onChange={(e) => {
              setError("");
              setLColumn(e.target.value);
            }}
            name=""
            id=""
          >
            {columns.length > 0 &&
              columns.map((item, i) => {
                if (item !== "geometry") {
                  return (
                    <option key={i} value={item}>
                      {item}
                    </option>
                  );
                }
              })}
          </select>
        </div>
        <p>Select Operator</p>
        <div className="row">
          <i className="fa fa-percent"></i>
          <select
            value={operator}
            onChange={(e) => {
              setError("");
              setOperator(e.target.value);
            }}
            name=""
            id=""
          >
            {operators.length > 0 &&
              operators.map((item, i) => {
                if (item !== "geometry") {
                  return (
                    <option key={i} value={item}>
                      {item}
                    </option>
                  );
                }
              })}
          </select>
        </div>
        <p>Your Input</p>
        <div className="row">
          <i className="fa fa-search "></i>
          <input
            className="query"
            onChange={(e) => {
              setError("");
              setValue(e.target.value);
            }}
            type="text"
          />
        </div>
        <p>{error}</p>
        <div className="div2equal">
          <button
            style={{ backgroundColor: "orange" }}
            onClick={() => {
              setOperator("Select Operator");
              setValue("");
              setLColumn("Select Column");
              props.resetMap();
            }}
          >
            <i className="fa fa-refresh ">&#xf021;</i>
          </button>
          <button
            onClick={() => {
              addToMap();
            }}
          >
            <i className="fa fa-check "></i>
          </button>
        </div>
      </div>
    </div>
  );
}
