import { useEffect, useState } from "react";
import "../../Styles/stats.scss";
import BarGraphExpense from "./BarGraphExpense";
import BubbleChartMonth from "./BubbleChartMonths";
import CustomPieChart from "./CustomPieChart";
import CustomBarChart from "./CustomBarChart";
import StackedAreaChart from "./StackedAreaChart";
import HorizontalChart from "./HorizontalChart";
import { useLayoutEffect } from "react";
import { useRef } from "react";
import html2canvas from "html2canvas";
import assignment from "../../assets/imgs/assignment.png";
import absent from "../../assets/imgs/absent.png";
import active from "../../assets/imgs/active.png";
import CombinedBarChart from "./CombinedBarChart";

export default function WPStats(props) {
  const jwt = require("jsonwebtoken");
  const [data, setData] = useState(null);
  const [pie, setPie] = useState(null);
  const [first, setFirst] = useState(null);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);

  function getToday() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}${month}${day}`;
  }

  function getEndMonth() {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const endOfMonth = new Date(year, month, 0);
    const formattedDay = endOfMonth.getDate().toString().padStart(2, "0");
    const formattedMonth = month.toString().padStart(2, "0");
    return `${year}-${formattedMonth}-${formattedDay}`;
  }

  function findFirstDay(inputString) {
    if (inputString.length >= 2) {
      return inputString.slice(0, -2) + "01";
    } else {
      return "01";
    }
  }

  useEffect(() => {
    setStart(getToday());
    setEnd(getEndMonth());
    setFirst(findFirstDay(getToday()));
  }, []);

  useEffect(() => {
    if (start) {
      fetch(`/api/workplan/stats/charts/${start}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          const uniqueDates = [...new Set(data.Type.map((item) => item.date))];
          const aggregatedDataArray = [];

          uniqueDates.forEach((date) => {
            const filteredData = data.Type.filter((item) => item.date === date);
            const dateObject = { date };

            filteredData.forEach((item) => {
              dateObject[item.category] = item.value;
            });

            // Get the day of the week abbreviation
            const dayAbbreviation = new Date(date).toLocaleDateString("en-US", {
              weekday: "short",
            });
            dateObject["day"] = dayAbbreviation;

            aggregatedDataArray.push(dateObject);
          });

          console.log(aggregatedDataArray);
          setData(aggregatedDataArray);
          setPie(data.HR);
        })
        .catch((e) => {});
    }
  }, [start]);

  return (
    <div className="stats">
      <div className="div2equal">
        <TopSection title="Daily Performance" start={start} end={start} />
        <TopSection title="Monthly Performance" start={first} end={end} />
      </div>
      <div className="div31">
        <div className="chart">
          <h4>Weekly Assignment</h4>
          <p>Distribution by Type</p>
          <CombinedBarChart
            colors={["#BA3D5D", "#6B275A"]}
            data={data ? data : null}
            aspect={2}
          />
        </div>
        <div className="chart">
          <h4>HR Cases</h4>
          <CustomPieChart
            colors={["#8BCB30", "#35B3F7", "#F5DE6D"]}
            data={pie}
            aspect={1}
          />
        </div>
      </div>
    </div>
  );
}

const TopSection = (props) => {
  const [data, setData] = useState(null);
  useEffect(() => {
    if (props.start && props.end) {
      fetch(`/api/workplan/stats/numbers/${props.start}/${props.end}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          console.log(data);
          setData(data);
        })
        .catch((e) => {});
    }
  }, [props.start, props.end]);

  return (
    <div className="topsection">
      <h1>{props.title}</h1>
      <div className="innerdivider">
        <div className="left">
          <div className="item">
            <h4>
              <img src={assignment} alt="" />
              {data ? data.Total : 0}
            </h4>
            <p>Officers Pool</p>
          </div>
          <div className="item">
            <h4>
              <img src={active} alt="" />
              {data ? data.Active : 0}
            </h4>
            <p>Working Today</p>
          </div>
          <div className="item">
            <h4>
              <img src={absent} alt="" />
              {data ? data.Absent : 0}
            </h4>
            <p>Not Working Today</p>
          </div>
        </div>
        <div className="right">
          <h4>Officers Assignment</h4>
          <CustomBarChart
            colors={["#F5AA61", "#F5DE6D"]}
            data={data ? data.Dist : null}
            aspect={1.5}
          />
        </div>
      </div>
    </div>
  );
};
