import { useEffect, useRef, useState } from "react";
import "../../Styles/messages.scss";
import WaveLoading from "../Util/WaveLoading";
import Pagination from "../Util/Pagination";

export default function Messages(props) {
  const [active, setActive] = useState("Sent Messages");
  const [refresh, setRefresh] = useState(false);
  const [download, setDownload] = useState(false);

  return (
    <div className="messages">
      <div className="container">
        <div className="topbar">
          <div
            onClick={() => {
              window.location.href = "/messages-new";
            }}
            className="new"
          >
            <i className="fa fa-plus"></i>
            <p>New Message</p>
          </div>
          <div className="top">
            <i
              onClick={() => {
                setRefresh(!refresh);
              }}
              className="fa fa-refresh"
            >
              &#xf021;
            </i>
            <i
              onClick={() => {
                setDownload(true);
              }}
              className="fa fa-download"
            ></i>

            <TopItem
              active={active}
              setActive={setActive}
              txt="Sent Messages"
            />
            <TopItem
              active={active}
              setActive={setActive}
              txt="Incoming Messages"
            />
            <TopItem active={active} setActive={setActive} txt="SOS" />
          </div>
        </div>
        {active == "Sent Messages" && <MyMessages />}
        {active == "Incoming Messages" && <IncomingMessages />}
        {active == "SOS" && <SOS />}
      </div>
    </div>
  );
}

const TopItem = (props) => {
  return (
    <div
      className={props.txt === props.active ? "active" : "topitem"}
      onClick={() => {
        props.setActive(props.txt);
      }}
    >
      <p>{props.txt}</p>
    </div>
  );
};

const MyMessages = (props) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  useEffect(() => {
    setLoading(true);
    fetch(`/api/messages/paginatedfrom/Outgoing/${offset * 12}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        setData(data);
        console.log(data);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [offset]);

  return (
    <div className="mlist">
      <h4>Messages</h4>
      <hr />
      <div className="list">
        {data &&
          data.data.length > 0 &&
          data.data.map((item, i) => {
            return <SMessage key={i} item={item} />;
          })}
      </div>
      {data && (
        <Pagination
          total={data.total}
          page={offset}
          handlePageChange={setOffset}
        />
      )}
      {loading && <WaveLoading />}
    </div>
  );
};

const IncomingMessages = (props) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  useEffect(() => {
    setLoading(true);
    fetch(`/api/messages/paginatedfrom/Incoming/${offset * 12}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        setData(data);
        console.log(data);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [offset]);

  return (
    <div className="mlist">
      <h4>Messages</h4>
      <hr />
      <div className="list">
        {data &&
          data.data.length > 0 &&
          data.data.map((item, i) => {
            return <SMessage key={i} item={item} />;
          })}
      </div>
      {data && (
        <Pagination
          total={data.total}
          page={offset}
          handlePageChange={setOffset}
        />
      )}
      {loading && <WaveLoading />}
    </div>
  );
};

const SOS = (props) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  useEffect(() => {
    setLoading(true);
    fetch(`/api/messages/paginatedfrom/SOS/${offset * 12}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        setData(data);
        console.log(data);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [offset]);

  return (
    <div className="mlist">
      <h4>Messages</h4>
      <hr />
      <div className="list">
        {data &&
          data.data.length > 0 &&
          data.data.map((item, i) => {
            return <SMessage key={i} item={item} />;
          })}
      </div>
      {data && (
        <Pagination
          total={data.total}
          page={offset}
          handlePageChange={setOffset}
        />
      )}
      {loading && <WaveLoading />}
    </div>
  );
};

const SMessage = (props) => {
  return (
    <div className="smessage">
      <h6>
        {props.item.createdAt.split("T")[0]} <br />
        <br /> {props.item.createdAt.split("T")[1].substring(0, 5)}
      </h6>
      <div>
        <h4>{props.item.Title}</h4>
        <p>{props.item.Message}</p>
      </div>
      <h5>{props.item.Type}</h5>
    </div>
  );
};
