import "../../Styles/reports.scss";
import { useEffect, useRef, useState } from "react";
import Loading from "../Util/Loading";
import Input from "../Util/Input";

export default function Reports(props) {
  return (
    <div className="reports">
      <StaffPerformance />
      <HealthSafety />
    </div>
  );
}

const StaffPerformance = (props) => {
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState(null);
  const [data, setData] = useState(null);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  useEffect(() => {
    const today = new Date();
    const firstDate = new Date(today.getFullYear(), today.getMonth(), 2);
    const lastDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);

    console.log(firstDate.toISOString());
    setStart(firstDate.toISOString().split("T")[0]);
    setEnd(lastDate.toISOString().split("T")[0]);
    if (start != "" && end != "") {
      getData();
    }
  }, []);

  useEffect(() => {
    if (start != "" && end != "") {
      getData();
    }
  }, [start, end]);

  function getData() {
    setData(null);
    setLoading(true);
    fetch(`/api/workplan/reports/performance/${start}/${end}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.length > 0) {
          let dt = data[0];
          const c = Object.keys(dt);
          setColumns(c.slice(0, 10));
          setData(data);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  }

  const saveData = (data) => {
    if (data.length > 0) {
      let rows = [];
      rows.push(Object.keys(data[0]));
      data.map((item) => {
        rows.push(Object.values(item));
      });
      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "data.csv");
      document.body.appendChild(link);
      link.click();
    }
  };

  return (
    <div className="list">
      <div className="div1auto">
        <h4>Staff Performance</h4>

        <i
          onClick={() => {
            if (data) {
              saveData(data);
            }
          }}
          className="fa fa-download download"
        ></i>
      </div>
      <div className="filter">
        <Input
          handleChange={(e) => {
            setStart(e);
          }}
          type="date"
          value={start}
          label="Start Date"
        />
        <Input
          handleChange={(e) => {
            setEnd(e);
          }}
          type="date"
          value={end}
          label="End Date"
        />
      </div>
      <div className="data">
        <div className="head">
          <h4>No.</h4>
          {columns &&
            columns.map((e, i) => {
              return <h4 key={i}>{e}</h4>;
            })}
        </div>
        {data &&
          data.length > 0 &&
          data.map((item, i) => {
            return <Item key={i} item={item} index={i} />;
          })}
      </div>
      {loading && <Loading />}
    </div>
  );
};

const HealthSafety = (props) => {
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState(null);
  const [data, setData] = useState(null);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  useEffect(() => {
    const today = new Date();
    const firstDate = new Date(today.getFullYear(), today.getMonth(), 2);
    const lastDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);

    console.log(firstDate.toISOString());
    setStart(firstDate.toISOString().split("T")[0]);
    setEnd(lastDate.toISOString().split("T")[0]);
    if (start != "" && end != "") {
      getData();
    }
  }, []);

  useEffect(() => {
    if (start != "" && end != "") {
      getData();
    }
  }, [start, end]);

  function getData() {
    setData(null);
    setLoading(true);
    fetch(`/api/hr/reports/performance/${start}/${end}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.length > 0) {
          let dt = data[0];
          const c = Object.keys(dt);
          setColumns(c.slice(0, 10));
          setData(data);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  }

  const saveData = (data) => {
    if (data.length > 0) {
      let rows = [];
      rows.push(Object.keys(data[0]));
      data.map((item) => {
        rows.push(Object.values(item));
      });
      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "data.csv");
      document.body.appendChild(link);
      link.click();
    }
  };

  return (
    <div className="list">
      <div className="div1auto">
        <h4>Health, Safety, and Disciplinary Issues</h4>

        <i
          onClick={() => {
            if (data) {
              saveData(data);
            }
          }}
          className="fa fa-download download"
        ></i>
      </div>
      <div className="filter">
        <Input
          handleChange={(e) => {
            setStart(e);
          }}
          type="date"
          value={start}
          label="Start Date"
        />
        <Input
          handleChange={(e) => {
            setEnd(e);
          }}
          type="date"
          value={end}
          label="End Date"
        />
      </div>
      <div className="data">
        <div className="hrhead">
          <h4>No.</h4>
          {columns &&
            columns.map((e, i) => {
              return <h4 key={i}>{e}</h4>;
            })}
        </div>
        {data &&
          data.length > 0 &&
          data.map((item, i) => {
            return <HRItem key={i} item={item} index={i} />;
          })}
      </div>
      {loading && <Loading />}
    </div>
  );
};

const HRItem = (props) => {
  const [values, setValues] = useState(null);
  const [clicked, setClicked] = useState(false);
  useEffect(() => {
    if (props.item) {
      let dt = props.item;
      const v = Object.values(dt);
      setValues(v.slice(0, 10));
    }
  }, [props.item]);

  return (
    <>
      <div
        onClick={() => {
          setClicked(true);
        }}
        className="hritem"
      >
        <p>{props.index + 1}</p>
        {values &&
          values.map((e, i) => {
            return <p key={i}>{e}</p>;
          })}
      </div>
      {clicked && (
        <Popup item={props.item} setClicked={setClicked} index={props.index} />
      )}
    </>
  );
};

const Item = (props) => {
  const [values, setValues] = useState(null);
  const [clicked, setClicked] = useState(false);
  useEffect(() => {
    if (props.item) {
      let dt = props.item;
      const v = Object.values(dt);
      setValues(v.slice(0, 10));
    }
  }, [props.item]);

  return (
    <>
      <div
        onClick={() => {
          setClicked(true);
        }}
        className="item"
      >
        <p>{props.index + 1}</p>
        {values &&
          values.map((e, i) => {
            return <p key={i}>{e}</p>;
          })}
      </div>
      {clicked && (
        <Popup item={props.item} setClicked={setClicked} index={props.index} />
      )}
    </>
  );
};

const Popup = (props) => {
  const [values, setValues] = useState(null);
  const [cols, setCols] = useState(null);
  const [data, setData] = useState(null);
  useEffect(() => {
    if (props.item) {
      let ds = props.item;
      delete ds.geom;
      setData([ds]);
      let dt = props.item;
      delete dt.geom;
      delete dt.ID;
      delete dt.id;

      const v = Object.values(dt);
      const c = Object.keys(dt);
      setValues(v);
      setCols(c);
    }
  }, [props.item]);

  const DItem = (params) => {
    return (
      <div className="ditem">
        <h4>{params.column}</h4>
        <p>{params.value}</p>
      </div>
    );
  };

  const saveData = (data) => {
    if (data.length > 0) {
      let rows = [];
      rows.push(Object.keys(data[0]));
      data.map((item) => {
        rows.push(Object.values(item));
      });
      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "data.csv");
      document.body.appendChild(link);
      link.click();
    }
  };

  return (
    <div className="popup">
      <div className="container">
        <div className="div1auto">
          <h4>Data Item {props.index}</h4>

          <i
            onClick={() => {
              props.setClicked(false);
            }}
            style={{ color: "white" }}
            className="fa fa-times"
          ></i>
        </div>
        <div className="data">
          <div className="div2equal">
            {cols &&
              cols.map((c, i) => {
                return <DItem key={i} column={c} value={values[i]} />;
              })}
          </div>
        </div>
        <button
          onClick={() => {
            if (data) {
              saveData(data);
            }
          }}
        >
          Download Record
        </button>
      </div>
    </div>
  );
};
