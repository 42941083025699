import { useEffect } from "react";
import "../../Styles/singleofficer.scss";
import Map from "ol/Map";
import View from "ol/View";
import { useRef, useState } from "react";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import GeoJSON from "ol/format/GeoJSON";
import VectorSource from "ol/source/Vector";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import XYZ from "ol/source/XYZ";
import Overlay from "ol/Overlay";
import personImg from "../../assets/imgs/personImg.png";
import ReactDOMServer from "react-dom/server";
import {
  ScaleLine,
  ZoomToExtent,
  defaults as defaultControls,
} from "ol/control";
import Graticule from "ol/layer/Graticule";
import StackedAreaChart from "../Stats/StackedAreaChart";

export default function SingleOfficer(props) {
  const pathname = window.location.pathname.split("/");
  const [data, setData] = useState(null);
  const [time, setTime] = useState(null);
  const [basemap, setBasemap] = useState(new TileLayer({ title: "Basemap" }));
  const [graticule, setGraticule] = useState(
    new Graticule({
      strokeStyle: new Stroke({
        color: "rgba(0,0,0,0.5)",
        width: 2,
        lineDash: [0.5, 8],
      }),
      showLabels: true,
      wrapX: false,
      title: "Grid",
    })
  );
  const [map, setMap] = useState(null);
  const mapElement = useRef();
  mapElement.current = map;

  useEffect(() => {
    basemap.setSource(
      new XYZ({
        url: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
        crossOrigin: "anonymous",
      })
    );
    const initialMap = new Map({
      target: mapElement.current,
      layers: [basemap, graticule],
      view: new View({
        projection: "EPSG:4326",
        center: [36.45, -0.1],
        zoom: 12,
        maxZoom: 22,
      }),
      controls: defaultControls().extend([
        new ScaleLine({
          units: "metric",
          bar: false,
          text: "Scale",
        }),
      ]),
    });

    setMap(initialMap);
  }, []);

  useEffect(() => {
    if (map && data) {
      fetch(`/api/tracker/byid/${data.ID}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          console.log(data);
          if (data.time.length > 0) {
            let dt = [];
            data.time.map((e) => {
              dt.push({
                value: e.value,
                name: e.name.split("T")[1].substring(0, 8),
              });
            });
            setTime(dt);
          }
          if (data.data.length > 0) {
            let pts = [];
            data.data.map((e) => {
              pts.push({
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: [e.Longitude, e.Latitude],
                },
                properties: e,
              });
            });
            console.log(pts);
            let geojsonObject = {
              type: "FeatureCollection",
              features: pts,
            };
            const vectorSource = new VectorSource({
              features: new GeoJSON().readFeatures(geojsonObject),
            });

            const vectorLayer = new VectorLayer({
              source: vectorSource,
              style: new Style({
                image: new CircleStyle({
                  radius: 10,
                  fill: new Fill({
                    color: "rgba(255, 0, 0, 0.6)",
                  }),
                  stroke: new Stroke({
                    color: "#319FD3",
                    width: 1,
                  }),
                }),
              }),
            });

            map.addLayer(vectorLayer);

            // Create a popup overlay
            const popup = new Overlay({
              element: document.getElementById("popup"),
              positioning: "bottom-center",
              stopEvent: false,
              offset: [0, -10],
            });
            map.addOverlay(popup);

            const extent = vectorSource.getExtent();
            map.getView().fit(extent, { padding: [50, 50, 50, 50] });

            return () => {
              map.setTarget(null);
            };
          }
        })
        .catch((e) => {});
    }
  }, [map, data]);

  useEffect(() => {
    fetch(`/api/tracker/workplan/${pathname[2]}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error("");
      })
      .then((data) => {
        console.log(data);
        if (data.length > 0) {
          setData(data[0]);
        }
      })
      .catch((e) => {});
  }, []);

  function formatDate(x) {
    if (x == undefined || x.split("T").length < 1) return "";
    let date = "";
    let year =
      x.split("T")[0].split("-")[2] +
      "-" +
      x.split("T")[0].split("-")[1] +
      "-" +
      x.split("T")[0].split("-")[0];
    date +=
      x.split("T")[1].substring(0, 5).replace(":", "") + " Hrs    " + year;
    return date;
  }

  return (
    <div className="singleofficer">
      <div className="list">
        <h3>Security Officer Tracker</h3>
        <hr />
        <p>
          <span>Organization: </span> {data?.Organization}, {data?.Address},{" "}
          {data?.City}
        </p>
        <p>
          <span>Security Officer: </span> {data?.Name}, {data?.Phone},{" "}
          {data?.Email}
        </p>
        <div className="div2equal">
          <p
            style={{
              backgroundColor: data?.Shift == "Day" ? "orange" : "brown",
              padding: "2px",
              color: "white",
            }}
          >
            <span
              style={{
                backgroundColor: data?.Shift == "Day" ? "orange" : "brown",
                padding: "2px",
                color: "white",
                fontWeight: "400",
              }}
            >
              Shift:
            </span>
            {data?.Shift}
          </p>
          <p>
            <span>Assignment Type: </span> {data?.Category}
          </p>
        </div>
        <div className="div2equal">
          <p>
            <span>Checkin time: </span>{" "}
            {data?.Shift == "Day" ? "6.00 a.m." : "6.00 p.m."}
          </p>

          <p>
            <span>Checkout time: </span>{" "}
            {data?.Shift == "Day" ? "6.00 p.m." : "6.00 a.m."}
          </p>
        </div>
        <div className="div2equal">
          <p>
            <span>Actual Checkin time: </span> {formatDate(data?.ActualCheckIn)}
          </p>

          <p>
            <span>Actual Checkout time: </span>{" "}
            {formatDate(data?.ActualCheckOut)}
          </p>
        </div>
        <br />
        <br />
        <div className="pchart">
          <h3>Officer Movement</h3>
          <hr />
          <div ref={mapElement} className="pmap"></div>
          <div id="popup" className="ol-popup">
            <div id="popup-content"></div>
          </div>
        </div>

        <br />
        <br />
        <div className="pchart">
          <h3>Movement Tracker</h3>
          <hr />
          <br />
          <StackedAreaChart data={time ? time : []} />
        </div>
      </div>
    </div>
  );
}
