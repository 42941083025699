import { useEffect, useState } from "react";
import placeholder from "../../assets/imgs/placeholder.png";
import WaveLoading from "../Util/WaveLoading";
import Select from "../Util/Select";
import Pagination from "../Util/Pagination";
import "../../Styles/workplan.scss";

export default function SingleWP(props) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  var dayNames = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
  const { getMonths } = require("../../assets/data/data.js");
  const yy = ["2023", "2024", "2025"];
  const [year, setYear] = useState("2023");
  const [offset, setOffset] = useState(0);
  const [today, setToday] = useState("");
  const [month, setMonth] = useState("January");
  const [mm, setMM] = useState([]);
  const [dd, setDD] = useState([]);
  const pathname = window.location.pathname.split("/");

  useEffect(() => {
    const date = pathname[3];
    const d = new Date(date).toISOString().split("T")[0];
    setToday(d);
  }, []);

  useEffect(() => {
    if (today != null) {
      setData(null);
      setLoading(true);
      fetch(`/api/workplan/searchdate/${today}/${offset}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setLoading(false);
          setData(data);
          console.log(data);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [today, offset, props.refresh]);

  function downloadCSV(jsonArray, filename) {
    const headerRow = Object.keys(jsonArray[0]).join(",") + "\n";
    const csvContent = jsonArray
      .map((row) => {
        return Object.values(row).join(",");
      })
      .join("\n");
    const fullCSV = headerRow + csvContent;
    const blob = new Blob([fullCSV], { type: "text/csv" });
    const downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = filename;
    downloadLink.click();
  }

  return (
    <div className="workplan">
      <div className="container">
        <div className="topbar">
          <div
            onClick={() => {
              window.location.href = "/wp";
            }}
            className="new"
          >
            <i className="fa fa-arrow-left"></i>
            <p>Work Plans</p>
          </div>
          <div className="top">
            <i
              onClick={() => {
                setRefresh(!refresh);
              }}
              className="fa fa-refresh"
            >
              &#xf021;
            </i>
            <i
              onClick={() => {
                if (data) {
                  downloadCSV(data.data, "workplans.csv");
                }
              }}
              className="fa fa-download"
            ></i>
          </div>
        </div>
        <div className="dailywp">
          <div className="dlist">
            {data &&
              data?.data?.length > 0 &&
              data?.data?.map((item, i) => {
                return <SOItem key={i} item={item} />;
              })}
          </div>
          <Pagination
            totalItems={data?.total}
            currentPage={offset}
            onPageChange={setOffset}
          />
          {loading && <WaveLoading />}
        </div>
      </div>
    </div>
  );
}

const DateItem = (props) => {
  return (
    <div
      onClick={() => {
        props.setToday(props.item.today);
      }}
      className={props.item.today === props.today ? "active" : "dateitem"}
    >
      <p>{props.item.date}</p>
      <h4>{props.item.day}</h4>
    </div>
  );
};

const SOItem = (props) => {
  const colors = ["#FFF1E8", "#D7F3FF", "#FFF1F1", "#B5CB99", "#F1F4FF"];
  const [color, setColor] = useState("#e5e5e5");

  useEffect(() => {
    console.log(props.item.Reason);
    switch (props.item.Reason) {
      case "Normal":
        setColor("#e5e5e5");
        break;
      case "Reliever":
        setColor(colors[0]);
        break;
      case "Off Day":
        setColor(colors[1]);
        break;
      case "Sick Leave":
        setColor(colors[2]);
      case "Leave":
        setColor(colors[3]);
        break;
      case "AWOL":
        setColor(colors[4]);
        break;
      default:
        break;
    }
  }, [props.item]);

  return (
    <div style={{ backgroundColor: color }} className="soitem">
      <div className="title">
        <img src={props.item.Photo ? props.item.Photo : placeholder} alt="" />
        <div>
          <h4>{props.item.Name}</h4>
          <p>
            <b>Phone: </b> {props.item.Phone}
          </p>
          <p>
            <b>Employee No: </b> {props.item.EmployeeNo}
          </p>
        </div>
      </div>

      <h4>Shift Details</h4>
      <hr />
      <div className="div3equal">
        <p
          style={{
            backgroundColor: props.item.Shift == "Day" ? "orange" : "brown",
            padding: "2px",
            color: "white",
          }}
        >
          <b>Shift: </b> {props.item.Shift}
        </p>
        <p>
          <b>Date: </b> {props.item.Date}
        </p>
        <p>
          <b>Type: </b> {props.item.Reason}
        </p>
      </div>

      <h4>Organization Details</h4>
      <hr />
      <p>
        <b>Organization: </b>
        {props.item.Organization}
      </p>
      <p>
        <b>Address: </b> {props.item.City}, {props.item.Address}
      </p>
    </div>
  );
};
