import { useRef, useState, useEffect } from "react";
import Loading from "../Util/Loading";
import "../../Styles/hr.scss";
import Input from "../Util/Input";
import placeholder from "../../assets/imgs/placeholder.png";
import Select from "../Util/Select";
import WaveLoading from "../Util/WaveLoading";

export default function NewHR(props) {
  const [loading, setLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState("");
  const fileInputRef = useRef(null);
  const item = useRef();
  const dt = useRef();
  const cat = useRef();
  const pwd = useRef();
  const pathname = window.location.pathname.split("/");
  const [user, setUser] = useState(null);

  useEffect(() => {
    let url = `/api/mobile/${pathname[3]}`;

    fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setUser(data);
      })
      .catch((err) => {});
  }, []);

  const createUser = () => {
    const body = {
      UserID: user?.UserID,
      Type: item.current.getValue(),
      Category: cat.current.getValue(),
      Date: dt.current.getValue(),
    };

    setError("");

    const validateForm = () => {
      let result = true;

      const chck = Object.values(body);
      let valid = true;
      chck.map((item) => {
        if (item == "") {
          valid = false;
        }
      });

      if (!valid) {
        setError("All fields are required!");
        return false;
      }

      return result;
    };

    if (validateForm()) {
      const formData = new FormData();

      for (const i in body) {
        formData.append(i, body[i]);
      }
      setLoading(true);
      fetch(`/api/hr/register`, {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
        },
        body: formData,
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else throw Error("Creation Failed!!!");
        })
        .then((data) => {
          setLoading(false);
          if (data.success) {
            setError(data.success);
            setTimeout(() => {
              window.location.href = "/so/hr/so/" + pathname[3];
            }, 1000);
          } else {
            setError(data.error);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (selectedFile !== null) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  }, [selectedFile]);

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    const selectedFile = e.target.files[0];
    console.log("Selected file:", selectedFile);
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <div className="hr">
      <div className="container">
        <div className="createorg">
          <div className="cpcontainer">
            <div className="tp">
              <h3>New HR Item</h3>
              <h4
                onClick={() => {
                  window.location.href = "/so/hr/so/" + pathname[3];
                }}
              >
                <i className="fa fa-arrow-right"></i> List
              </h4>
            </div>
            <form
              className="bdy"
              onSubmit={(e) => {
                e.preventDefault();
              }}
              autoComplete="off"
            >
              <div className="div2equal">
                <div>
                  <div className="section">
                    <h4>Item Details</h4>
                    <hr />
                    <Input ref={item} type="text" label="Item" />

                    <Select
                      ref={cat}
                      data={["Disciplinary", "Excellence", "Safety"]}
                      label="Category *"
                    />
                    <Input ref={dt} type="date" label="Date *" />
                  </div>
                </div>

                <div className="section">
                  <h4>Officer Details</h4>
                  <hr />

                  <p>
                    <b>Name: </b> {user?.Name}
                  </p>
                  <p>
                    <b>Phone: </b> {user?.Phone}
                  </p>
                  <p>
                    <b>Email: </b> {user?.Email}
                  </p>
                  <p>
                    <b>Employee No: </b> {user?.EmployeeNo}
                  </p>
                </div>
              </div>

              <h6>{error}</h6>
              <button
                onClick={() => {
                  createUser();
                }}
              >
                Submit
              </button>
            </form>
            {loading && <WaveLoading />}
          </div>
        </div>
      </div>
    </div>
  );
}
