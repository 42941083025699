import React from "react";

function formatDate(inputDate) {
  const date = new Date(inputDate);

  // Format the day with the appropriate suffix
  const day = date.getDate();
  const dayWithSuffix = getDayWithSuffix(day);

  const options = { year: "numeric", month: "short" };
  const formattedDate = date.toLocaleDateString("en-US", options);

  return `${dayWithSuffix} ${formattedDate}`;
}

function getDayWithSuffix(day) {
  if (day >= 11 && day <= 13) {
    return `${day}th`;
  }

  switch (day % 10) {
    case 1:
      return `${day}st`;
    case 2:
      return `${day}nd`;
    case 3:
      return `${day}rd`;
    default:
      return `${day}th`;
  }
}

export default function SelectedUser(props) {
  function updateUser(status) {
    props.setLoading(true);
    fetch(`/api/${props.url}/${props?.userDetails?.UserID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ Status: !status }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        props.setLoading(false);
        props.setRefresh(!props.refresh);
      })
      .catch((err) => {
        props.setLoading(false);
      });
  }

  function deleteUser() {
    props.setLoading(true);
    fetch(`/api/${props.url}/${props?.userDetails?.UserID}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        props.setLoading(false);
        props.setRefresh(!props.refresh);
      })
      .catch((err) => {
        props.setLoading(false);
      });
  }

  return (
    <>
      <p>Name: {props?.userDetails?.Name}</p>
      <p>Email: {props?.userDetails?.Email}</p>
      <p>Phone: {props?.userDetails?.Phone}</p>
      <p>Position: {props?.userDetails?.Position}</p>
      <p>Role: {props?.userDetails?.Role}</p>
      {props?.userDetails?.County && (
        <p>County: {props?.userDetails?.County}</p>
      )}
      <p>Status: {props?.userDetails?.Status ? "Active" : "Disabled"}</p>
      <p>Date Created: {formatDate(props?.userDetails?.createdAt)}</p>
      <p>Date Updated: {formatDate(props?.userDetails?.updatedAt)}</p>

      <div className="actions">
        <h6
          onClick={() => {
            updateUser(props?.userDetails?.Status);
          }}
        >
          {props?.userDetails?.Status ? "Deactivate" : "Activate"}
        </h6>
        <h6
          onClick={() => {
            deleteUser();
          }}
        >
          Delete
        </h6>
      </div>
    </>
  );
}
