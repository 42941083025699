import "../../Styles/officers.scss";
import { useState } from "react";
import { useEffect } from "react";
import Pagination from "../Util/Pagination";
import WaveLoading from "../Util/WaveLoading";
import personImg from "../../assets/imgs/personImg.png";

export default function Officers(props) {
  const [data, setData] = useState(null);
  const [offset, setOffset] = useState(0);
  const [isloading, setIsLoading] = useState(false);
  const [today, setToday] = useState(new Date().toISOString().split("T")[0]);
  const [editing, setEditing] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [filter, setFilter] = useState(false);
  const [column, setColumn] = useState("Name");
  const [fcolumn, setFColumn] = useState(null);
  const [foperator, setFOperator] = useState(null);
  const [fvalue, setFValue] = useState(null);

  useEffect(() => {
    if (!fcolumn || !fvalue || !foperator) {
      setIsLoading(true);
      console.log(offset);
      fetch(`/api/tracker/paginated/${today}/${offset}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setIsLoading(false);
          console.log(data);
          if (data?.data?.length > 0) {
            setData(data);
          } else setData(null);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(true);
      fetch(
        `/api/tracker/filter/${today}/${fcolumn}/${foperator}/${fvalue}/${offset}`
      )
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setIsLoading(false);
          console.log(data);
          if (data?.data?.length > 0) {
            setData(data);
          } else setData(null);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }
  }, [offset, refresh, fvalue, foperator, fcolumn]);

  function searchCustomer(v) {
    let table = "";
    if (column == "Name") table = "Mobiles";
    else table = "Organizations";
    setIsLoading(true);
    fetch(`/api/tracker/search/${table}/${column}/${v}/${today}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setIsLoading(false);
        if (data?.data?.length > 0) {
          setData(data);
        } else setData(null);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }
  return (
    <div className="billingstats">
      <div className="list">
        <div className="bar">
          <div className="left">
            <h3>Security Officer Tracker</h3>
            <p>
              Security officers performance as at{" "}
              {new Date().toString().substring(0, 21)}
            </p>
          </div>

          <div className="search">
            <select
              onChange={(e) => {
                setColumn(e.target.value);
              }}
              name=""
              id=""
            >
              <option value="Name">Name</option>
              <option value="Organization">Organization</option>
            </select>
            <input
              onChange={(e) => {
                if (e.target.value == "") {
                  setRefresh(!refresh);
                  setOffset(0);
                } else {
                  searchCustomer(e.target.value);
                }
              }}
              type="text"
              placeholder="Name search"
              required
            />
            <i className="fa fa-search"></i>
          </div>
        </div>

        <div className="container">
          {data &&
            data?.data?.length > 0 &&
            data?.data?.map((item, i) => {
              return <Item key={i} index={i} item={item} />;
            })}
          <br />
          <br />
          {data?.total > 0 && (
            <Pagination
              totalItems={data?.total}
              onPageChange={(v) => {
                setOffset(v);
              }}
              currentPage={offset}
            />
          )}
        </div>
      </div>
      {isloading && <WaveLoading />}
    </div>
  );
}

const Item = (props) => {
  function formatDate(x) {
    let date = new Date(x);
    return date.toString().substring(0, 15);
  }

  return (
    <>
      <div
        className="row"
        onClick={() => {
          window.location.href = "/tracking/" + props.item.ID;
        }}
      >
        <img src={personImg} alt="" className="icon" />
        <div>
          <p>
            <span>Name: </span>
            {props.item.Name?.toLowerCase()}
          </p>
          <p>
            <span>Gender: </span>
            {props.item.Gender?.toLowerCase()}
          </p>
          <p>
            <span>Email: </span>
            {props.item.Email}
          </p>
          <p>
            <span>Phone No: </span>
            {props.item.Phone}
          </p>
        </div>

        <div>
          <p>
            <span>Assignment: </span> {props.item.Organization}
          </p>
          <p>
            <span>Address: </span> {props.item.Address}
          </p>
          <p>
            <span>City: </span> {props.item.City}
          </p>
        </div>
        <div>
          <p>
            <span>Date: </span> {formatDate(props.item.Date)}
          </p>
          <p
            style={{
              backgroundColor: props.item.Shift == "Day" ? "orange" : "brown",
              padding: "2px",
              color: "white",
            }}
          >
            <span
              style={{
                backgroundColor: props.item.Shift == "Day" ? "orange" : "brown",
                padding: "2px",
                color: "white",
                fontWeight: "400",
              }}
            >
              Shift:
            </span>
            {props.item.Shift}
          </p>
          <p>
            <span>Assignment Type: </span> {props.item.Category}
          </p>
          <p>
            <span>Status: </span> {props.item.WStatus ? "Complete" : "Pending"}
          </p>
        </div>
        <div className="banner">
          {props.item.ActualCheckin != null &&
            props.item.ActualCheckout == null && <p>Checked in</p>}
          {props.item.ActualCheckin != null &&
            props.item.ActualCheckout != null && <p>Checked out</p>}
        </div>
      </div>
    </>
  );
};
